export const StockSectorDict = {
  "-1": "Nan",
  "0": "Healthcare",
  "1": "Basic Materials",
  "2": "Financial",
  "3": "Real Estate",
  "4": "Industrials",
  "5": "Technology",
  "6": "Consumer Cyclical",
  "7": "Consumer Defensive",
  "8": "Communication Services",
  "9": "Utilities",
  "10": "Energy"
}

export const StockIndustryDict = {
  "-1": "Nan",
  "0": "Diagnostics & Research",
  "1": "Aluminum",
  "2": "Shell Companies",
  "3": "REIT - Mortgage",
  "4": "Airlines",
  "5": "Rental & Leasing Services",
  "6": "Semiconductors",
  "7": "Building Products & Equipment",
  "8": "Specialty Retail",
  "9": "Consumer Electronics",
  "10": "REIT - Diversified",
  "11": "Gold",
  "12": "Airports & Air Services",
  "13": "Aerospace & Defense",
  "14": "Asset Management",
  "15": "Specialty Industrial Machinery",
  "16": "Drug Manufacturers - General",
  "17": "Medical Distribution",
  "18": "Banks - Regional",
  "19": "Biotechnology",
  "20": "Beverages - Brewers",
  "21": "Auto & Truck Dealerships",
  "22": "Specialty Business Services",
  "23": "Medical Devices",
  "24": "Infrastructure Operations",
  "25": "Conglomerates",
  "26": "Drug Manufacturers - Specialty & Generic",
  "27": "REIT - Residential",
  "28": "Health Information Services",
  "29": "Business Equipment & Supplies",
  "30": "Gambling",
  "31": "Insurance - Diversified",
  "32": "Medical Care Facilities",
  "33": "Grocery Stores",
  "34": "Communication Equipment",
  "35": "Software - Infrastructure",
  "36": "Semiconductor Equipment & Materials",
  "37": "Engineering & Construction",
  "38": "Information Technology Services",
  "39": "REIT - Retail",
  "40": "Pollution & Treatment Controls",
  "41": "Farm Products",
  "42": "Auto Parts",
  "43": "Staffing & Employment Services",
  "44": "Credit Services",
  "45": "Software - Application",
  "46": "Security & Protection Services",
  "47": "Advertising Agencies",
  "48": "Closed-End Fund - Equity",
  "49": "Utilities - Regulated Electric",
  "50": "Electrical Equipment & Parts",
  "51": "Insurance - Life",
  "52": "Apparel Retail",
  "53": "Utilities - Diversified",
  "54": "Entertainment",
  "55": "Insurance - Property & Casualty",
  "56": "Education & Training Services",
  "57": "Silver",
  "58": "Farm & Heavy Construction Machinery",
  "59": "Insurance - Specialty",
  "60": "REIT - Hotel & Motel",
  "61": "Textile Manufacturing",
  "62": "Industrial Distribution",
  "63": "Insurance Brokers",
  "64": "Specialty Chemicals",
  "65": "Medical Instruments & Supplies",
  "66": "Telecom Services",
  "67": "Oil & Gas Midstream",
  "68": "Packaging & Containers",
  "69": "Broadcasting",
  "70": "REIT - Specialty",
  "71": "Oil & Gas Refining & Marketing",
  "72": "Furnishings, Fixtures & Appliances",
  "73": "Internet Retail",
  "74": "Food Distribution",
  "75": "Computer Hardware",
  "76": "Internet Content & Information",
  "77": "Healthcare Plans",
  "78": "Closed-End Fund - Foreign",
  "79": "Leisure",
  "80": "Metal Fabrication",
  "81": "Oil & Gas E&P",
  "82": "Chemicals",
  "83": "Electronic Components",
  "84": "Waste Management",
  "85": "Utilities - Renewable",
  "86": "Trucking",
  "87": "Thermal Coal",
  "88": "Restaurants",
  "89": "REIT - Office",
  "90": "Oil & Gas Equipment & Services",
  "91": "Solar",
  "92": "Electronics & Computer Distribution",
  "93": "Utilities - Independent Power Producers",
  "94": "Mortgage Finance",
  "95": "Utilities - Regulated Gas",
  "96": "Integrated Freight & Logistics",
  "97": "Electronic Gaming & Multimedia",
  "98": "Other Precious Metals & Mining",
  "99": "Closed-End Fund - Debt",
  "100": "Utilities - Regulated Water",
  "101": "Auto Manufacturers",
  "102": "Banks - Diversified",
  "103": "Consulting Services",
  "104": "Resorts & Casinos",
  "105": "Other Industrial Metals & Mining",
  "106": "Lumber & Wood Production",
  "107": "Real Estate Services",
  "108": "Beverages - Wineries & Distilleries",
  "109": "Personal Services",
  "110": "Capital Markets",
  "111": "Packaged Foods",
  "112": "Discount Stores",
  "113": "Travel Services",
  "114": "Oil & Gas Drilling",
  "115": "Oil & Gas Integrated",
  "116": "Tobacco",
  "117": "Residential Construction",
  "118": "Footwear & Accessories",
  "119": "Department Stores",
  "120": "Financial Data & Stock Exchanges",
  "121": "Beverages - Non-Alcoholic",
  "122": "Uranium",
  "123": "Agricultural Inputs",
  "124": "Scientific & Technical Instruments",
  "125": "REIT - Healthcare Facilities",
  "126": "Household & Personal Products",
  "127": "Lodging",
  "128": "Steel",
  "129": "Paper & Paper Products",
  "130": "Marine Shipping",
  "131": "Railroads",
  "132": "REIT - Industrial",
  "133": "Apparel Manufacturing",
  "134": "Building Materials",
  "135": "Recreational Vehicles",
  "136": "Copper",
  "137": "Home Improvement Retail",
  "138": "Luxury Goods",
  "139": "Real Estate - Development",
  "140": "Publishing",
  "141": "Tools & Accessories",
  "142": "Insurance - Reinsurance",
  "143": "Pharmaceutical Retailers",
  "144": "Coking Coal",
  "145": "Real Estate - Diversified",
  "146": "Confectioners",
  "147": "Financial Conglomerates",
  "148": "Exchange Traded Fund"
}